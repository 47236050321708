import request from '@/utils/request'

export function orderList (query) {
  return request({
    url: '/wechat/order/',
    method: 'get',
    params: query
  })
}
export function create_pick_list (parameter) {
  return request({
    url: '/wechat/create_pick_list/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function order_to_examine (parameter) {
  return request({
    url: '/wechat/audit/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function orderDetail (order_id) {
  return request({
    url: '/wechat/order/detail/?orderId=' + order_id,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function parentCategoryName () {
  return request({
    url: '/wechat/parent_category/name/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function order_refund (parameter) {
  return request({
    url: '/wechat/order/refund/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function detailGoods (id) {
  return request({
    url: '/goods/detail/',
    method: 'get',
    params: { id }
  })
}

export function editGoods (data) {
  return request({
    url: '/wechat_mall_goods/',
    method: 'put',
    data
  })
}

export function listCatAndBrand () {
  return request({
    url: '/goods/catAndBrand',
    method: 'get'
  })
}

export function orderRefundList (query) {
  return request({
    url: '/wechat/order/refund/',
    method: 'get',
    params: query
  })
}
