<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="订单商品列表"
        :sub-title="order_no"
        @back="() => $router.go(-1)"
      >
        <a-divider orientation="left">
          订单信息
        </a-divider>
        <a-row :gutter="16">
          <a-col :md="4" :sm="8" span="4">
            <span>收货人：{{ orderInfo.consignee }}</span>
          </a-col>
          <a-col :md="4" :sm="8" span="4">
            <span>地址：{{ orderInfo.address }}</span>
          </a-col>
          <a-col :md="4" :sm="8" span="4">
            <span>实付款：{{ (orderInfo.actualPrice/1).toFixed(2) }}</span>
          </a-col>
          <a-col :md="4" :sm="8" span="4">
            <span>模式：{{ this.$Dictionaries.deliveryMethod[orderInfo.deliveryMethod] }}</span>
          </a-col>
        </a-row>
      </a-page-header>
      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :showPagination="false"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="img" slot-scope="img">
          <a-avatar
            v-if="img"
            slot="img"
            :src="img"
            shape="square"
            @click="openPreview(img)"
          />
        </span>
      </s-table>

      <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
        <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { Tag } from 'ant-design-vue'
import { orderDetail } from '@/api/wx_shopmall_order'
export default {
  name: 'TableList',
  components: {
    STable
  },
  props: {},
  data () {
    return {
      order_no: '',
      order_id: '',
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      userInfo: Object,
      orderInfo: Object,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      previewvisible: false,
      previewurl: '',
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品',
          dataIndex: 'picUrl',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '商品名称',
          align: 'center',
          dataIndex: 'goodsName'
        },
        {
          title: '单价',
          align: 'center',
          dataIndex: 'price',
          customRender: (text) => (text).toFixed(2)
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'number'
        },
        {
          title: '单位',
          align: 'center',
          dataIndex: 'specifications',
          customRender: (specifications) => <span>
      {
        specifications.map(specification => {
        let color = specification.length > 8 ? 'geekblue' : 'green'
        if (specification === 'loser') {
          color = 'volcano'
        }
        return <Tag color={color} key={specification}>{specification}</Tag>
      })}
    </span>
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderDetail(this.$route.query.id)
          .then(({ data }) => {
            const localData = { entries: [] }
            localData.entries = data.orderGoods
            this.userInfo = data.user
            this.orderInfo = data.order
            return localData
          })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created () {
    this.order_id = this.$route.query.id
    console.log('电费参数2222', this.order_id)
  },
  methods: {
    getFileSuffix: function (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    }
  }
}
</script>
